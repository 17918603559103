export class sliders {
    constructor() {
        this.initNativeSliders();
    }

    initNativeSliders() {
        const nativeSliders = document.querySelectorAll('.slider-wrapper');

        nativeSliders.forEach((slider) => {
            const sliderTrack = slider.querySelector<HTMLElement>('.slider-track');
            const sliderControlPrev = slider.querySelector<HTMLButtonElement>('.slider-control__prev');
            const sliderControlNext = slider.querySelector<HTMLButtonElement>('.slider-control__next');
            const sliderControlSeparator = slider.querySelector<HTMLSpanElement>('.slider-control__separator');
            const sliderDots = slider.querySelector<HTMLElement>('.slider-dots');
            const sliderDotsItems = sliderDots?.querySelectorAll<HTMLElement>('.slider-dots__item button');
            const sliderItems = slider.querySelectorAll<HTMLElement>('.slider-item');
            const sliderTrackGap = window.getComputedStyle(sliderTrack!).getPropertyValue('gap');
            const sliderItemWidth = sliderItems[0]?.clientWidth! + parseInt(sliderTrackGap);
            const isMobileView = () => window.innerWidth < 1200;

            if (sliderControlPrev && sliderControlNext) {
                window.addEventListener('resize', () => {
                    if (sliderTrack!.scrollWidth > sliderTrack!.clientWidth) {
                        sliderControlPrev?.classList.remove('hidden');
                        sliderControlSeparator?.classList.remove('hidden');
                        sliderControlNext?.classList.remove('hidden');
                        sliderDots?.classList.remove('hidden');
                    } else {
                        sliderControlPrev?.classList.add('hidden');
                        sliderControlSeparator?.classList.add('hidden');
                        sliderControlNext?.classList.add('hidden');
                        sliderDots?.classList.add('hidden');
                    }
                });
                window.dispatchEvent(new Event('resize'));

                sliderControlNext.addEventListener('click', () => {
                    scroll('right');
                });

                sliderControlPrev.addEventListener('click', () => {
                    scroll('left');
                });

                if (sliderDots) {
                    sliderDotsItems?.forEach((item, index) =>
                        item.addEventListener('click', () => dotsNavigation(index))
                    );
                }

                const dotsNavigation = (index: number) => {
                    const activeDotIndex = getActiveSlideIndex();
                    scroll(index > activeDotIndex ? 'right' : 'left', index - activeDotIndex);
                };

                const scroll = (direction: 'right' | 'left', items: number = 1) => {
                    if (direction === 'right') {
                        const maxScrollLeft = sliderTrack!.scrollWidth - sliderTrack!.clientWidth;
                        let newScrollLeft = sliderItemWidth * items;

                        if (sliderTrack!.scrollLeft + sliderItemWidth > maxScrollLeft) {
                            newScrollLeft = maxScrollLeft - sliderTrack!.scrollLeft;
                        }

                        sliderTrack?.scrollBy({ left: newScrollLeft, behavior: 'smooth' });
                    } else {
                        let newScrollLeft = sliderItemWidth * items;

                        if (sliderTrack!.scrollLeft - sliderItemWidth < 0) {
                            newScrollLeft = sliderTrack!.scrollLeft;
                        }

                        sliderTrack?.scrollBy({ left: Math.abs(newScrollLeft!) * -1, behavior: 'smooth' });
                    }
                };

                sliderTrack?.addEventListener('scroll', () => {
                    slider.classList.add('is-scrolling');
                    requestAnimationFrame(setActiveSlide);

                    const isFullyScrolled =
                        sliderTrack!.scrollWidth - Math.floor(sliderTrack!.scrollLeft) === sliderTrack?.clientWidth;
                    const isAtStart = sliderTrack?.scrollLeft === 0;

                    if (isAtStart) {
                        sliderControlPrev?.setAttribute('disabled', 'disabled');
                        sliderControlNext?.removeAttribute('disabled');
                    }

                    if (isFullyScrolled) {
                        sliderControlNext?.setAttribute('disabled', 'disabled');
                        sliderControlPrev?.removeAttribute('disabled');
                    }

                    if (!isAtStart && !isFullyScrolled) {
                        sliderControlNext?.removeAttribute('disabled');
                        sliderControlPrev?.removeAttribute('disabled');
                    }
                });

                sliderTrack?.addEventListener('scrollend', () => {
                    slider.classList.remove('is-scrolling');
                });

                const getActiveSlideIndex = () => {
                    const scrollLeft = sliderTrack!.scrollLeft;
                    const containerWidth = sliderTrack!.offsetWidth;
                    const scrollWidth = sliderTrack!.scrollWidth;

                    if (isMobileView()) {
                        if (scrollLeft === 0) {
                            return 0;
                        } else if (scrollLeft + containerWidth >= scrollWidth) {
                            return sliderItems.length - 1;
                        } else {
                            const centerPoint = scrollLeft + containerWidth / 2;
                            let closestIndex = 0;
                            let closestDistance = Infinity;

                            sliderItems.forEach((slide, index) => {
                                const slideCenter = slide.offsetLeft + slide.offsetWidth / 2;
                                const distance = Math.abs(centerPoint - slideCenter);
                                if (distance < closestDistance) {
                                    closestDistance = distance;
                                    closestIndex = index;
                                }
                            });

                            return closestIndex;
                        }
                    } else {
                        const threshold = containerWidth / 3;
                        let activeIndex = 0;

                        sliderItems.forEach((slide, index) => {
                            if (slide.offsetLeft - scrollLeft <= threshold) {
                                activeIndex = index;
                            }
                        });

                        return activeIndex;
                    }
                };

                const setActiveSlide = () => {
                    const isXlView = () => window.innerWidth > 1200;
                    const isUwView = () => window.innerWidth > 1920;
                    const activeIndex = getActiveSlideIndex();

                    const toggleActiveClass = (items: NodeListOf<Element> | undefined, index: number) => {
                        items?.forEach((item, i) => {
                            item.classList.toggle('active', i === index);
                        });
                    };

                    if (sliderDots) {
                        const getPages = (): number => {
                            if (isXlView() && !isUwView()) {
                                return convertToNumber(sliderDots!.dataset.dotsCountXl);
                            }

                            if (isUwView()) {
                                return convertToNumber(sliderDots!.dataset.dotsCountUw);
                            }

                            return -1;
                        };

                        toggleDots(getPages());
                    }

                    toggleActiveClass(sliderItems, activeIndex);
                    toggleActiveClass(sliderDotsItems, activeIndex);
                };

                const toggleDots = (pages: number) => {
                    sliderDotsItems?.forEach((item, index) => item.classList.toggle('d-none', index >= pages));
                };

                const convertToNumber = (value: any): number => {
                    const num = parseFloat(value);
                    return isNaN(num) ? -1 : num;
                };

                window.addEventListener('resize', () => setActiveSlide());
                setActiveSlide();
            }
        });
    }
}
